<template>
  <div class="radio-default">
    <input type="radio"
           class="radio-default__input"
           :id="'radio-default-' + id"
           :checked="checked"
           :disabled="disabled"
           @change="change"
           :name="name"
    >
    <label :for="'radio-default-' + id" class="radio-default__label" v-bind:class="{'d-flex': cardFlex}">
      {{ label }}

      <slot name="card">

      </slot>
    </label>

    <div class="radio-default__help-text">
      {{ helpText }}
    </div>

    <span class="radio-default__error error-field" v-if="error && errorTxt">{{errorTxt}}</span>
  </div>
</template>

<script>
  export default {
    name: "RadioDefault",

    data () {
      return {
        id: null,
        checked: this.value,
      }
    },

    props: [
      'label',
      'value',
      'helpText',
      'errorTxt',
      'error',
      'disabled',
      'name',
      'card',
      'cardFlex',
    ],

    watch: {
      value(newVal) {
        this.checked = newVal
      }
    },

    mounted () {
      this.id = this._uid
    },

    methods: {

      change: function() {
        // this.checked = !this.checked;
        this.$emit('input', this.checked);
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";


  .radio-default{
    display: flex;
    position: relative;
    width: 100%;

    &__input{
      display: none;
    }

    &__label{
      position: relative;
      padding-left: 24px;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      color: $black;


      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 15px;
        height: 15px;
        border: 2px solid #BBAD9C;
        box-sizing: border-box;
        display: flex;
        border-radius: 50%;
      }


      &:after{
        content: '';
        position: absolute;
        left: 1px;
        top: 3px;
        width: 13px;
        height: 13px;
        border: 1px solid transparent;
        display: flex;
        opacity: 0;
        border-radius: 50%;
        transform: scale(0.6);
        background-color: $brown;
      }
    }

    &__input:checked + &__label{
      &:before{
        /*background-color: $brown;*/
        border-color: $brown;
      }

      &:after{
        opacity: 1;
      }
    }

    &__input:disabled + &__label{
      &:before{
        opacity: 0.4;
      }
    }

    &__input:checked:disabled + &__label{
      &:after{
        opacity: 0.4;
      }
    }

    &__help-text{
      position: absolute;
      top: 100%;
      left: 24px;
      font-size: 12px;
      line-height: 18px;
      color: $borderBrown;
    }

    &__error{
      position: absolute;
      bottom: -18px;
      left: 0;
      font-size: 11px;
      line-height: 13px;
      color: $orange;
    }
  }

  .empty-label{
    min-height: 16px;
  }
</style>
