import {
  PROFORM_CATEGORY_SAVED,
  PROFORM_CATEGORY_SEARCH,
  PROFORM_TYPE_MATERIAL,
  PROFORM_TYPE_NAMING
} from "../../staticData/staticVariables";

export const proformMixins = {
  methods: {
    // changeCategoryProfiles({val, nameVal}) {
    //   return new Promise((resolve) => {
    //     this.product.unsetProductProformType()
    //     this.product.setProductProformDataCategory(val)
    //
    //     if(val.id === PROFORM_CATEGORY_SEARCH){
    //       return
    //     }
    //
    //     if(val.id === PROFORM_CATEGORY_SAVED){
    //       this.$store.dispatch('getSavedProform',
    //         this.$store.getters.getIsAdminRights === 'admin' ?
    //           this.product.product.userId :
    //           this.$store.getters.GET_COMMON_AUTHORIZED.user.id
    //       )
    //       return
    //     }
    //
    //     let filter = '?filter[ProformAttachmentProformCategoryId]=' + val.id
    //
    //     this.$store.dispatch('fetchProformProductCategory', filter).then((response) => {
    //       let data = response.data.data
    //
    //       let localProformTypes = this.product.product.proformData.proformTypes
    //
    //       data.map(type => {
    //         Object.keys(type).map(item => {
    //
    //           if(type[item].length > 0){
    //
    //             // if click on search naming type
    //             if(nameVal !== '' && item == PROFORM_TYPE_NAMING){
    //               delete nameVal.category
    //               localProformTypes[item].typeValue = nameVal
    //             } else {
    //               localProformTypes[item].typeValue = type[item][0]
    //             }
    //           }
    //
    //           localProformTypes[item].typeOptions = type[item]
    //         })
    //       })
    //
    //       this.getHSCodeFromProform({
    //         category: val.id,
    //         naming: localProformTypes[PROFORM_TYPE_NAMING].typeValue.value.id,
    //         material: localProformTypes[PROFORM_TYPE_MATERIAL].typeValue.value.id
    //       }).then(response => {
    //         this.product.setHSCode(this._.first(response.data).code)
    //       })
    //
    //
    //
    //       this.product.setProductProformTypes(localProformTypes)
    //       resolve('complete')
    //     })
    //
    //   })
    //
    // },

    getProformTypeLabel(proformTypes, type) {
      if (this.isBelarus) {
        return proformTypes[type.id].typeName[type.translationStorage.en.name + '_Ru'].name + '(Рус)'
      }
      return proformTypes[type.id].typeName[type.translationStorage.en.name + '_Ua'].name + '(Укр)'
    },

    getHSCodeFromProform({category, naming, material, hsCodeByCountry = null}) {
      // console.log('1'+category, '2'+naming, '3'+material);

      const query = new this.Query();
      let url = '?';
      let myQuery = query
        .for('posts')

      myQuery.where('HscodeCategoryId', category)
      myQuery.where('HscodeProformNameId', naming)
      myQuery.where('HscodeProformMaterialId', material)

      if(hsCodeByCountry) {
        myQuery.where('byCountryId', hsCodeByCountry?.id)
      }

      url = url + myQuery.url().split('?')[1]

      return this.$store.dispatch('getHSCodeForProform', url)
    },

    changeProformSelectItem(proform, hsCodeByCountry = false) {
      this.getHSCodeFromProform({
        category: proform.product.proformData.proformCategory.id,
        naming: proform.product.proformData.proformTypes[PROFORM_TYPE_NAMING].typeValue.value.id,
        material: proform.product.proformData.proformTypes[PROFORM_TYPE_MATERIAL].typeValue.value.id,
        hsCodeByCountry: hsCodeByCountry,
      }).then(response => {
        let HSCodeData = this._.first(response.data)
        if(HSCodeData){
          proform.setHSCode(this._.first(response.data).code)
          return
        }
        proform.setHSCode('')

      })
    },

    changeCategoryProform({val, nameVal, item}, hsCodeByCountry = null, noAutoSetMaterial = false) {

      return new Promise((resolve) => {
        item.unsetProductProformType()
        item.setProductProformDataCategory(val)

        if(val.id === PROFORM_CATEGORY_SEARCH){
          return
        }

        if(val.id === PROFORM_CATEGORY_SAVED){
          this.$store.dispatch('getSavedProform',
            this.$store.getters.getIsAdminRights === 'admin' ?
              item.product.userId :
              this.isSubUser ?
              this.$store.getters.GET_COMMON_AUTHORIZED.user?.company_owner?.id :
              this.$store.getters.GET_COMMON_AUTHORIZED.user.id
          )
          return
        }

        let proformLang = '&sortByLang=' + this.proformUserConfig.lang

        let filter = '?filter[ProformAttachmentProformCategoryId]=' + val.id + proformLang

        if(!this.proformChanged) this.proformChanged = true

        this.$store.dispatch('fetchProformProductCategory', filter).then((response) => {
          let data = response.data.data

          let localProformTypes = item.product.proformData.proformTypes

          data.map(type => {
            Object.keys(type).map(item => {

              if(type[item].length > 0){

                // if click on search naming type
                if(nameVal !== '' && item == PROFORM_TYPE_NAMING){
                  delete nameVal.category
                  localProformTypes[item].typeValue = nameVal
                }
                else if (!(+item === +PROFORM_TYPE_MATERIAL && noAutoSetMaterial)) {
                  localProformTypes[item].typeValue = type[item][0]
                }
              }

              localProformTypes[item].typeOptions = type[item]
            })
          })

          let naming = localProformTypes[PROFORM_TYPE_NAMING]?.typeValue?.value?.id ? localProformTypes[PROFORM_TYPE_NAMING].typeValue.value.id : ''
          let material = localProformTypes[PROFORM_TYPE_MATERIAL]?.typeValue?.value?.id ? localProformTypes[PROFORM_TYPE_MATERIAL].typeValue.value.id : ''

          if(val.id) {
            this.getHSCodeFromProform({
              category: val.id,
              naming: naming,
              material: material,
              hsCodeByCountry: hsCodeByCountry,
            }).then(response => {
              let HSCodeData = this._.first(response.data)
              if(HSCodeData){
                item.setHSCode(this._.first(response.data).code)
                return
              }
              item.setHSCode('')

            }).catch(error => this.createErrorLog(error))
          } else {
            item.setHSCode('')
          }

          item.setProductProformOptions(localProformTypes)

          if(this.proformChanged) this.proformChanged = false

          resolve('complete')
        }).catch(error => this.createErrorLog(error))

      })
    },

    initialProformSelectOptions({val, item}) {
      return new Promise((resolve) => {
        let proformLang = '&sortByLang=' + this.proformUserConfig.lang
        let filter = '?filter[ProformAttachmentProformCategoryId]=' + val?.id + proformLang

        this.$store.dispatch('fetchProformProductCategory', filter).then((response) => {
          let data = response.data.data

          let localProformTypes = item.product.proformData.proformTypes

          data.map(type => {
            Object.keys(type).map(item => {
              localProformTypes[item].typeOptions = type[item]
            })
          })

          item.setProductProformTypes(localProformTypes)
          resolve('complete')
        }).catch(error => this.createErrorLog(error))

      })
    },

    initializeProductProform(product = false) {
      return new Promise((resolve) => {
        this.$store.dispatch('fetchProformProductInitialize').then((response) => {
          let proformTypesFormatted = this.getProformTypesFormatted()

          if(product){
            product.setProductProformTypes(proformTypesFormatted)
          } else {
            this.product.setProductProformTypes(proformTypesFormatted)
          }

          resolve(response)
        }).catch(error => this.createErrorLog(error))
      })
    },

    initializeProductProformArray(arr, index = false) {
      if(index !== false) {
        let proformTypesFormatted = this.getProformTypesFormatted()
        arr[index].setProductProformTypes(proformTypesFormatted)
        return
      }

      return new Promise((resolve) => {
        this.$store.dispatch('fetchProformProductInitialize').then((response) => {
          let proformTypesFormatted = this.getProformTypesFormatted()

          arr.map(item => {
            item.setProductProformTypes(proformTypesFormatted)
          })

          resolve(response)
        }).catch(error => this.createErrorLog(error))
      })
    },

    getProformTypesFormatted() {
      let proformTypes = this.$store.getters.getProformProductInitialize.proformTypes
      let proformTypesFormatted = {}

      let proformTypeOptions = [{}]

      proformTypes.map((item) => {
        proformTypesFormatted[item.id] = {}
        proformTypesFormatted[item.id].id = item.id
        proformTypesFormatted[item.id].typeName = {}

        proformTypesFormatted[item.id].typeName = {
          [item.translationStorage.en.name + '_Ua']: item.translationStorage.ua,
          [item.translationStorage.en.name + '_Ru']: item.translationStorage.ru,
          [item.translationStorage.en.name + '_En']: item.translationStorage.en
        }

        proformTypesFormatted[item.id].typeValue = ''

        proformTypesFormatted[item.id].typeOptions = proformTypeOptions
      })

      return proformTypesFormatted
    }


  }
}
