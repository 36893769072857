import {checkValid} from "./functions/ModelsValidate";

export function Dimensions() {

  this.checkValid = checkValid

  this.data = {
    dimensionsObj: '',
    name: '',
    weight: '',
    height: '',
    width: '',
    dimensionLength: '',
    widthLB: '',
    widthOZ: '',

    weightLB: '',
    weightOZ: '',
  }

  this.validation = {
    name: false,
    weight: false,
    height: false,
    width: false,
    dimensionLength: false,
    widthLB: false,
    widthOZ: false,
    weightLB: false,
    weightOZ: false,
  }

  this.validationTranslate = {
    name: '',
    weight: '',
    height: '',
    width: '',
    dimensionLength: '',
    widthLB: '',
    widthOZ: '',
    weightLB: '',
    weightOZ: '',
  }

  this.validationTxt = {
    name: false,
    weight: false,
    height: false,
    width: false,
    dimensionLength: false,
    widthLB: false,
    widthOZ: false,
    weightLB: false,
    weightOZ: false,
  }

}


/*****************   SETTERS   ******************/
Dimensions.prototype.setDimensionsName = function(val){
  this.data.name = val;
}

Dimensions.prototype.setDimensionsWeight = function(val){
  this.data.weight = val;
}

Dimensions.prototype.setDimensionsHeight = function(val){
  this.data.height = val;
}

Dimensions.prototype.setDimensionsWidth = function(val){
  this.data.width = val;
}

Dimensions.prototype.setDimensionsLength = function(val){
  this.data.dimensionLength = val;
}

Dimensions.prototype.setDimensionsWidthLB = function(val){
  this.data.widthLB = val;
}

Dimensions.prototype.setDimensionsWidthOZ = function(val){
  this.data.widthOZ = val;
}

Dimensions.prototype.setDimensionsWeightLB = function(val){
  this.data.weightLB = val;
}

Dimensions.prototype.setDimensionsWeightOZ = function(val){
  this.data.weightOZ = val;
}

Dimensions.prototype.setDimensionsItem = function(val){
  this.data.weight = val.weight;
  this.data.height = val.height;
  this.data.width = val.width;
  this.data.dimensionLength = val.dimensionLength;
}

Dimensions.prototype.setDimensionsData = function(val){
  this.data.name = val.name;
  this.data.weight = val.weight;
  this.data.height = val.height;
  this.data.width = val.width;
  this.data.dimensionLength = val.dimensionLength;
  this.data.widthLB = val.widthLB;
  this.data.widthOZ = val.widthOZ;
  this.data.weightLB = val.weightLB;
  this.data.weightOZ = val.weightOZ;
}


/*****************   GETTERS   ******************/
Dimensions.prototype.getDimensionsName = function(){
  return this.data.name;
}

Dimensions.prototype.getDimensionsWeight = function(){
  return this.data.weight;
}

Dimensions.prototype.getDimensionsHeight = function(){
  return this.data.height;
}

Dimensions.prototype.getDimensionsWidth = function(){
  return this.data.width;
}

Dimensions.prototype.getDimensionsLength = function(){
  return this.data.dimensionLength;
}

Dimensions.prototype.getDimensionsWidthLB = function(){
  return this.data.widthLB;
}

Dimensions.prototype.getDimensionsWidthOZ = function(){
  return this.data.widthOZ;
}

Dimensions.prototype.getDimensionsWeightLB = function(){
  return this.data.weightLB;
}

Dimensions.prototype.getDimensionsWeightOZ = function(){
  return this.data.weightOZ;
}




/*****************   VALIDATION   ******************/

Dimensions.prototype.dimensionsValidate = function(){

  let validationItems = {
    weight: this.data.weight,
    height: this.data.height,
    width: this.data.width,
    dimensionLength: this.data.dimensionLength,
  }

  let validationOptions = {
    weight: {type: ['numeric', 'not-zero', 'empty']},
    height: {type: ['numeric', 'not-zero', 'empty']},
    width: {type: ['numeric', 'not-zero', 'empty']},
    dimensionLength: {type: ['numeric', 'not-zero', 'empty']},
  }


  return this.checkValid(validationItems, validationOptions)
}


Dimensions.prototype.dimensionsPartialValidate = function(
  {
    name,
    weight,
    height,
    width,
    dimensionLength,
    widthLB,
    widthOZ,
    weightLB,
    weightOZ
  }){

  let validationItems = {
    name: this.data.name,
    weight: this.data.weight,
    height: this.data.height,
    width: this.data.width,
    dimensionLength: this.data.dimensionLength,
    widthLB: this.data.widthLB,
    widthOZ: this.data.widthOZ,
    weightLB: this.data.weightLB,
    weightOZ: this.data.weightOZ,
  }

  let validationOptions = {
    name: {type: name ? ['empty'] : []},
    weight: {type: weight ? ['numeric', 'not-zero', 'empty'] : []},
    height: {type: height ? ['numeric', 'not-zero', 'empty'] : []},
    width: {type: width ? ['numeric', 'not-zero', 'empty'] : []},
    dimensionLength: {type: dimensionLength ? ['numeric', 'not-zero', 'empty'] : []},
    widthLB: {type: widthLB ? ['numeric', 'not-zero', 'empty'] : []},
    widthOZ: {type: widthOZ ? ['numeric', 'not-zero', 'empty'] : []},
    weightLB: {type: weightLB && this.data.weightOZ.length === 0 ? ['numeric', 'not-zero', 'empty'] : []},
    weightOZ: {type: weightOZ && this.data.weightLB.length === 0 ? ['numeric', 'not-zero', 'empty'] : []},
  }

  console.log(this.data.weightOZ.length);
  console.log(this.data.weightLB.length);
  console.log(validationOptions);


  return this.checkValid(validationItems, validationOptions)
}



Dimensions.prototype.dimensionsPrepareData = function() {
  return {
    "name" : this.data.name,
    "weight" : this.data.weight,
    "height" : this.data.height,
    "width" : this.data.width,
    "dimensionLength" : this.data.dimensionLength,
    "widthLB" : this.data.widthLB,
    "widthOZ" : this.data.widthOZ,
    "weightLB" : this.data.weightLB,
    "weightOZ" : this.data.weightOZ,
  }
}
