import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {User} from "../../../globalModels/User";
import {Dimensions} from "../../../globalModels/Dimensions";
import {
	CONSOLIDATION_TYPES,
	CONSOLIDATION_STATUSES_ARRAY,
	CONSOLIDATION_SYSTEM_STATUSES_ARRAY
} from "../../../../staticData/staticVariables";
import {ProformProduct} from "../../../globalModels/ProformProduct";
import {ProductForCell} from "../../../globalModels/ProductForCell";
import {Product} from "../../../globalModels/Product";
import {ProductCells} from "../../../globalModels/ProductCells";
import {PromoCode} from "@/components/globalModels/PromoCode";
import {Order} from "@/components/globalModels/Order";
const _ = require('lodash');


export function Consolidation() {

	this.checkValid = checkValid

	this.data = {
		id: '',
		Order: new Order(),
		historyOfChanged: '',
		consolidationDestination: '',
		consolidationUnion: null,
		warehouse: '',
		recipientWarehouse: '',
		fedexWarehouses: '',
		shippedAt: null,
		receivedAt: null,
		createdAt: null,

		deliveryService: '',
		oldDeliveryService: '',
		trackingNumber: '',
		systemTrackingNumber: '',
		comment: '',
		toxicSubstance: false,
		dropCertification: false,
		senderContactName: '',
		relatedOrders: [],
		proformProducts: [new ProformProduct()],
		proformProductsDeletedIds: [],
		proformOrders: [new ProformProduct()],
		proformOrdersDeletedIds: [],

		/**
		 * Product for add product in popup
		 */
		productsDefault: [new Product()],

		ProductForCell: new ProductForCell(),
		productsForCellArray: [],

		proformProductsForTable: [],

		lunchBreak: false,
		deliveryDate: null,
		timeCourierTo: '',
		timeCourierFrom: '',
		timeNotAvailableTo: '',
		timeNotAvailableFrom: '',

		User: new User(),
		Dimensions: new Dimensions(),

		promoCodes: [new PromoCode()],

		numberOfBoxes: null,
		volumeWeight: '',
		shippingCost: '',

		status: null,
		systemStatus: null,
		oldStatus: null,

		adminOrderAmount: null,
		orderAmount: '',
		packagingAmount: '',
		orderProcessFee: '',

		orderWarehouse: null,
		vatAmount: '',
		vatAmountAdminChanged: '',

		orderNotification: null,
		labelFileUUID: '',
		sendType: 'warehouse',
		sendTypes: {
			warehouse: 'warehouse',
			sender: 'sender'
		},

		sendFrom: null,
	}

	this.validation = {
		id: false,
		consolidationDestination: false,
		consolidationUnion: false,
		warehouse: false,
		shippedAt: false,
		receivedAt: false,

		deliveryService: false,
		oldDeliveryService: false,
		trackingNumber: false,
		comment: false,
		toxicSubstance: false,
		dropCertification: false,
		senderContactName: false,
		relatedOrders: false,

		deliveryDate: false,
		timeCourierTo: false,
		timeCourierFrom: false,
		timeNotAvailableTo: false,
		timeNotAvailableFrom: false,

		numberOfBoxes: false,
		status: false,

		adminOrderAmount: false,
		orderAmount: false,
		packagingAmount: false,
		orderProcessFee: false,
	}

	this.validationTranslate = {
		id: '',
		consolidationDestination: '',
		consolidationUnion: '',
		warehouse: '',
		shippedAt: '',
		receivedAt: '',

		deliveryService: '',
		oldDeliveryService: '',
		trackingNumber: '',
		comment: '',
		toxicSubstance: '',
		dropCertification: '',
		senderContactName: '',
		relatedOrders: '',

		deliveryDate: '',
		timeCourierTo: '',
		timeCourierFrom: '',
		timeNotAvailableTo: '',
		timeNotAvailableFrom: '',

		numberOfBoxes: '',
		status: '',

		adminOrderAmount: '',
		orderAmount: '',
		packagingAmount: '',
		orderProcessFee: '',
	}

	this.validationTxt = {
		id: false,
		consolidationDestination: false,
		consolidationUnion: false,
		warehouse: false,
		shippedAt: false,
		receivedAt: false,

		deliveryService: false,
		oldDeliveryService: false,
		trackingNumber: false,
		comment: false,
		toxicSubstance: false,
		dropCertification: false,
		senderContactName: false,
		relatedOrders: false,

		deliveryDate: false,
		timeCourierTo: false,
		timeCourierFrom: false,
		timeNotAvailableTo: false,
		timeNotAvailableFrom: false,

		numberOfBoxes: false,
		status: false,

		adminOrderAmount: false,
		orderAmount: false,
		packagingAmount: false,
		orderProcessFee: false,
	}

	/**
	 * Getters
	 */
	this.getId = () => {
		return this.data.id
	}
	this.getConsolidationDestination = () => {
		return this.data.consolidationDestination
	}
	this.getConsolidationUnion = () => {
		return this.data.consolidationUnion
	}
	this.getWarehouse = () => {
		return this.data.warehouse
	}
	this.getRecipientWarehouse = () => {
		return this.data.recipientWarehouse
	}
	this.getOrderWarehouse = () => {
		return this.data.orderWarehouse
	}
	this.getShippedAt = () => {
		return this.data.shippedAt
	}
	this.getReceivedAt = () => {
		return this.data.receivedAt
	}
	this.getCreatedAt = () => {
		return this.data.createdAt
	}
	this.getDeliveryService = () => {
		return this.data.deliveryService
	}
	this.getOldDeliveryService = () => {
		return this.data.oldDeliveryService
	}
	this.getTrackingNumber = () => {
		return this.data.trackingNumber
	}
	this.getSystemTrackingNumber = () => {
		return this.data.systemTrackingNumber
	}
	this.getComment = () => {
		return this.data.comment
	}
	this.getToxicSubstance = () => {
		return this.data.toxicSubstance
	}
	this.getDropCertification = () => {
		return this.data.dropCertification
	}
	this.getSenderContactName = () => {
		return this.data.senderContactName
	}
	this.getNumberOfBoxes = () => {
		return this.data.numberOfBoxes
	}
	this.getRelatedOrders = () => {
		return this.data.relatedOrders
	}

	this.getDeliveryDate = () => {
		return this.data.deliveryDate
	}
	this.getTimeCourierTo = () => {
		return this.data.timeCourierTo
	}
	this.getTimeCourierFrom = () => {
		return this.data.timeCourierFrom
	}
	this.getTimeNotAvailableTo = () => {
		return this.data.timeNotAvailableTo
	}
	this.getTimeNotAvailableFrom = () => {
		return this.data.timeNotAvailableFrom
	}
	this.getShippingCost = () => {
		return this.data.shippingCost
	}
	this.getStatus = () => {
		return this.data.status
	}
	this.getSystemStatus = () => {
		return this.data.systemStatus
	}
	this.getOldStatus = () => {
		return this.data.oldStatus
	}
	this.getProductsDefault = () => {
		return this.data.productsDefault
	}

	this.getProductsForCellArray = () => {
		return this.data.productsForCellArray
	}

	this.getAdminOrderAmount = () => {
		return this.data.adminOrderAmount
	}
	this.getOrderAmount = () => {
		return this.data.orderAmount
	}
	this.getPackagingAmount = () => {
		return this.data.packagingAmount
	}
	this.getOrderProcessFee = () => {
		return this.data.orderProcessFee
	}
	this.getVatAmount = () => {
		return this.data.vatAmount
	}
	this.getVatAmountAdminChanged = () => {
		return this.data.vatAmountAdminChanged
	}
	this.getLabelFileUUID = () => {
		return this.data.labelFileUUID
	}

	/**
	 * Setters
	 */
	this.setId = (val) => {
		this.data.id = val
	}
	this.setHistoryOfChanged = (val) => {
		this.data.historyOfChanged = val
	}
	this.setConsolidationDestination = (val) => {
		this.data.consolidationDestination = val
	}
	this.setConsolidationUnion = (val) => {
		this.data.consolidationUnion = val
	}
	this.setWarehouse = (val) => {
		this.data.warehouse = val
	}
	this.setRecipientWarehouse = (val) => {
		this.data.recipientWarehouse = val
	}
	this.setOrderWarehouse = (val) => {
		this.data.orderWarehouse = val
	}
	this.setShippedAt = (val) => {
		this.data.shippedAt = val
	}
	this.setReceivedAt = (val) => {
		this.data.receivedAt = val
	}
	this.setCreatedAt = (val) => {
		this.data.createdAt = val
	}
	this.setDeliveryService = (val) => {
		this.data.deliveryService = val
	}
	this.setOldDeliveryService = (val) => {
		this.data.oldDeliveryService = val
	}
	this.setTrackingNumber = (val) => {
		this.data.trackingNumber = val
	}
	this.setSystemTrackingNumber = (val) => {
		this.data.systemTrackingNumber = val
	}
	this.setComment = (val) => {
		this.data.comment = val
	}
	this.setToxicSubstance = (val) => {
		this.data.toxicSubstance = val
	}
	this.setDropCertification = (val) => {
		this.data.dropCertification = val
	}
	this.setSenderContactName = (val) => {
		this.data.senderContactName = val
	}
	this.setNumberOfBoxes = (val) => {
		this.data.numberOfBoxes = val
	}
	this.setRelatedOrders = (val, that, withProform = true) => {

		if(this.data.relatedOrders.length >= val.length){
			this.data.relatedOrders = val
			return
		}


		let filteredNewOrders = val.filter(item => {
			return !this.data.relatedOrders.includes(item)
		})

		let findNewOrder = _.first(filteredNewOrders)

		this.data.relatedOrders = val

		if (withProform) {

			let query = new that.Query();
			let url = '?';
			let myQuery = query
				.for('posts')

			//select orders with consolidation and label type id
			myQuery.where('OrderFbmId', findNewOrder.id)
			myQuery.appends('total_proform_data')

			url = url + myQuery.limit(100000).page(1).url().split('?')[1]

			that.$store.dispatch('fetchPublicOrdersFBM', url).then(response => {
				//filter orders in draft
				let respData = that.getRespPaginateData(response)
				findNewOrder = _.first(respData)
				this.addProformByProduct(findNewOrder.total_proform_data, true, that)
			})

		}
	}

	this.setDeliveryDate = (val) => {
		this.data.deliveryDate = val
	}
	this.setTimeCourierTo = (val) => {
		this.data.timeCourierTo = val
	}
	this.setTimeCourierFrom = (val) => {
		this.data.timeCourierFrom = val
	}
	this.setTimeNotAvailableTo = (val) => {
		this.data.timeNotAvailableTo = val
	}
	this.setTimeNotAvailableFrom = (val) => {
		this.data.timeNotAvailableFrom = val
	}
	this.setShippingCost = (val) => {
		this.data.shippingCost = val
	}
	this.setStatus = (val) => {
		this.data.status = val
	}
	this.setSystemStatus = (val) => {
		this.data.systemStatus = val
	}
	this.setOldStatus = (val) => {
		this.data.oldStatus = val
	}
	this.setAdminOrderAmount = (val) => {
		this.data.adminOrderAmount = val
	}
	this.setOrderAmount = (val) => {
		this.data.orderAmount = val
	}
	this.setPackagingAmount = (val) => {
		this.data.packagingAmount = val
	}
	this.setOrderProcessFee = (val) => {
		this.data.orderProcessFee = val
	}
	this.setVatAmount = (val) => {
		this.data.vatAmount = val
	}
	this.setVatAmountAdminChanged = (val) => {
		this.data.vatAmountAdminChanged = val
	}
	this.setOrderNotification = (val) => {
		this.data.orderNotification = val
	}
	this.setLabelFileUUID = (val) => {
		this.data.labelFileUUID = val
	}

	this.setProductsDefault = (val) => {
		if(val === ''){
			this.data.productsDefault = [new Product()]
		}
	}

	this.addOrder = (val) => {
		this.data.relatedOrders.push(val)
	}

	this.addProformProductForTable = (item) => {


		console.log(11312,item);
		// no need to check for duplicate if no elements
		if (this.data.proformProductsForTable.length === 0) {
			this.data.proformProductsForTable.push(item)

			return
		}

		//check for duplicate items
		let duplicateItem = this.checkForProformProductDuplicates(this.data.proformProductsForTable, item)
		console.log('duplicateItem object:',duplicateItem);

		if (duplicateItem === false && this.data.proformProductsForTable.length > 0) {
			this.data.proformProductsForTable.push(item)
			return
		}

		if (duplicateItem !== false && this.data.proformProductsForTable.length > 0) {
			this.data.proformProductsForTable[duplicateItem].product.itemsQuantity += item.product.itemsQuantity
			console.log('table array after found duplicate', this.data.proformProductsForTable)
		}

	}

	this.checkForProformProductDuplicates = (array, item) => {
		// variable to save found duplicate object, because method "some" returns only a Boolean value
		let duplicateItemIndex = false

		//first loop through all items in array, will stop when we found duplicate
		array.some((arrItem, index) => {
			let propDifferenceFlag = false
			//loop through all "proformTypes" properties of the element, stop when we find the difference compared to element in array
			Object.keys(item.product.proformData.proformTypes).some(typeKey => {
				//check if element we compare to has the same property and if the value ids are the same
				if (
					!(Object.prototype.hasOwnProperty.call(arrItem.product.proformData.proformTypes, typeKey) &&
					(item.product.proformData.proformTypes[typeKey]?.typeValue?.value?.id === arrItem.product.proformData.proformTypes[typeKey]?.typeValue?.value?.id))
				) {
					//if different, set the flag to true and exit loop
					propDifferenceFlag = true
					return false
				}
			})

			//if flag is not set, compare the price
			// if price is the same too, we found our duplicate, so we exit the loop and return index of the duplicate element in the array, otherwise continue
			if (propDifferenceFlag === false && arrItem.product.price === item.product.price &&
				(!arrItem.product.productObject?.id || arrItem.product.productObject.id === item.product.productObject.id)) {
				duplicateItemIndex = index
				console.log('duplicateItem object:',arrItem, 'item:', item);
				return true
			}

		})

		//return either the element index of duplicate if found, or false
		return duplicateItemIndex
	}

	this.removeProformProductsForTableItem = (index) => {
		this.data.proformOrdersDeletedIds.push(this.data.proformProductsForTable[index].product.proformEntityId)
		this.data.proformProductsForTable.splice(index, 1)
	}
}


/**
 * Global Setters
 */

Consolidation.prototype.setConsolidationItem = function (
	item,
	destinationData,
	that,
	deliveryServices = false,
	orderFees = false,
	unions = false,
	recipientWarehouse = [],
) {

	this.setId(item?.id)
	this.setHistoryOfChanged(item?.activityUUID)
	this.setConsolidationDestination(destinationData.find(destinationItem => {
		return destinationItem.id === item?.consolidation_destination_id
	}))

	if (deliveryServices) {
		this.setDeliveryService(deliveryServices.find(deliveryItem => {
			return deliveryItem.id === item.delivery_service_id
		}))
		this.setOldDeliveryService(deliveryServices.find(deliveryItem => {
			return deliveryItem.id === item.old_delivery_service_id
		}))
	}

	if (unions) {
		this.setConsolidationUnion(unions.find(unionItem => {
			return unionItem.id === item.consolidation_union_id
		}))
	}

	if (this.getConsolidationDestination()?.id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id) {
		this.setWarehouse(recipientWarehouse.find(recipientItem => {
			return recipientItem?.id === item?.warehouse_storage?.warehouse_id
		}))
	}
	else if (item?.warehouse_id) {
		this.setWarehouse(item?.warehouse_id)
	}

	//если нет склада, то отправка от своего имени
	if (!item?.warehouse_id &&
		(this.getConsolidationDestination()?.id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id ||
			this.getConsolidationDestination()?.id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id)
		) {
		this.data.sendType = this.data.sendTypes.sender
	}

	this.setOrderWarehouse(item['warehouse_storage'])

	this.setOrderNotification(item?.orderNotification)

	this.setTrackingNumber(item?.user_tracking_number || '')
	this.setSystemTrackingNumber(item?.tracking_number || '')

	this.data.Dimensions.setDimensionsLength(item?.dimensions?.length)
	this.data.Dimensions.setDimensionsWidth(item?.dimensions?.width)
	this.data.Dimensions.setDimensionsHeight(item?.dimensions?.height)
	this.data.Dimensions.setDimensionsWeight(item?.dimensions?.weight)

	item.promocodes.map((code, index) => {
		if(this.data.promoCodes[index]) {
			this.data.promoCodes[index].setCode(code?.code)
		} else {
			this.addPromoCode()
			this.data.promoCodes[index].setCode(code?.code)
		}
	})

	this.setSenderContactName(item?.sender_first_name)

	this.setDropCertification(item?.drop_certification === 1)
	this.setToxicSubstance(item?.toxic_substance === 1)

	this.setVatAmount(item?.vat_amount)
	this.setVatAmountAdminChanged(item?.vat_amount_admin_changed)

	this.setComment(item?.comment || '')

	this.setAdminOrderAmount(item?.admin_order_amount)
	this.setOrderAmount(item?.order_amount)
	this.setPackagingAmount(item?.packaging_amount)
	if (orderFees && orderFees.length > 0) {
		this.setOrderProcessFee(orderFees.find(feeItem => {
			return feeItem.cost === +item?.order_process_fee
		}))
	}

	this.setShippingCost(item?.order_amount)
	this.data.User.setUser(item?.user)
	this.setShippedAt(item?.shipped_at ? that.$moment(item?.shipped_at).format('YYYY-MM-DD') : null)
	this.setReceivedAt(item?.received_at ? that.$moment(item?.received_at).format('YYYY-MM-DD') : null)
	this.setDeliveryDate(item?.delivery_date ? that.$moment(item?.delivery_date).format('YYYY-MM-DD') : null)
	this.setCreatedAt(item?.created_at ? that.$moment(item?.created_at).format('YYYY-MM-DD') : null)

	this.setStatus(CONSOLIDATION_STATUSES_ARRAY.find(itemStatus => {
		return itemStatus.value === item?.mark
	}))

	this.setSystemStatus(CONSOLIDATION_SYSTEM_STATUSES_ARRAY.find(itemStatus => {
		return itemStatus.value === item?.status
	}))

	this.setOldStatus(CONSOLIDATION_STATUSES_ARRAY.find(itemStatus => {
		return itemStatus.value === item?.mark
	}))

	this.addProformByProduct(item.proform_data, true, that)

	let orderIds = []
	if(Object.keys(item.orders).length > 0){
		orderIds = Object.keys(item.orders).map(orderId => {
			return orderId
		}).join(',')
	}

	if (orderIds.length > 0) {

		const query = new that.Query();
		let url = '?';
		let myQuery = query
			.for('posts')

		myQuery.where('OrderFbmId', orderIds)

		url = url + myQuery.limit(100000).page(1).url().split('?')[1]
		if (that.isAdmin) {
			url = url + '&withoutDraft=true'
		}

		that.$store.dispatch('fetchOrdersFBMListForConsolidation', url).then(response => {
			//filter orders in draft
			this.setRelatedOrders(that.getRespPaginateData(response), that, false)

			if(item.orders_checked) {
				Object.keys(item.orders_checked).map(orderChecked => {
					let foundOrder = that._.find(this.data.relatedOrders, {id: Number(orderChecked)})
					if(foundOrder) {
						foundOrder['orderChecked'] = item.orders_checked[orderChecked] === 1
					}
				})
			}
		})
	}




	this.setProductsForCellArray(item?.product_data, item, that)

	///////////////

	if(item.available_courier_time !== null) {
		let courierTime = item.available_courier_time.split('|')
		this.setTimeCourierFrom(that.$moment(courierTime[0], 'HH:mm').format('HH:mm'))
		this.setTimeCourierTo(that.$moment(courierTime[1], 'HH:mm').format('HH:mm'))
	}
	if(item.not_available_courier_time !== null) {
		let courierTime = item.not_available_courier_time.split('|')
		this.setTimeNotAvailableFrom(that.$moment(courierTime[0], 'HH:mm').format('HH:mm'))
		this.setTimeNotAvailableTo(that.$moment(courierTime[1], 'HH:mm').format('HH:mm'))
	}

	if (item.fileEntities && item.fileEntities.length > 0) {
		let findLabelFile = item.fileEntities.find(fileItem => {
			return fileItem.extension === 'pdf' && fileItem.type === 'userDocumentPackageFileType'
		})
		this.setLabelFileUUID(findLabelFile?.uuid || '')
	}

	this.data.Order.setOrderOBJ(item)
	this.data.sendFrom = item.send_from
}

Consolidation.prototype.createProductModelAndCellsArray = function (productEntity, item, that) {
	/**
	 * create and Product model
	 * @type {Product}
	 */

	let currentProduct = productEntity?.product
	// console.log(currentProduct, item['product_data'], 33333);
	let proformDataEntities = productEntity?.proform
	let proformEntityId = proformDataEntities ? Object.keys(proformDataEntities)[0] : 0
	let proformData = null
	if (proformEntityId > 0) {
		proformData = proformDataEntities[proformEntityId]
	}
	else {
		proformData = {
			proform_category: '',
			proformOptions: {
				handmade: '1',
				item_price: 0,
				item_quantity: productEntity.item_quantity,
			},
			proformTypes: null,
			values: [],
		}
	}

	let productData = {
		productInstance: currentProduct,
		proformData: proformData,
	}

	let newProduct = new ProformProduct()

	that.initializeProductProformArray({product: newProduct}, 'product')

	newProduct.setProductItem(productData, proformEntityId, that)
	newProduct.setProductItemsQuantity(productEntity.item_quantity)
	newProduct.setHSCode(proformData.proformOptions?.hs_code ? proformData.proformOptions?.hs_code : '')
	console.log(12345, newProduct, proformData);
	// if (productEntity?.hs_code) {
	// 	newProduct.setHSCode(productEntity.hs_code)
	// }

	let userProductCells = []
	let userCells = []
	let freeCells = []
	let mixedFreeCells = []

	userProductCells = [...userProductCells, ...this.addExistCellsForProduct(productEntity)]

	let existCell = userProductCells.length > 0

	userProductCells = [...userProductCells, ...this.addCellsWithProductInWarehouse(currentProduct, existCell, userProductCells)]

	userCells = [...userCells, ...this.addOtherUserCells(item, existCell, userProductCells)]

	freeCells = [...freeCells, ...this.addOtherFreeCells(item)]

	mixedFreeCells = [...mixedFreeCells, ...this.addMixedFreeCells(item)]

	return {newProduct, cells: {userProductCells, userCells, freeCells, mixedFreeCells}, existCell}
}

Consolidation.prototype.addExistCellsForProduct = function (product) {
	/**
	 * Добавляем ячейки которые уже сохранены в заказе
	 */

	let cells = []
	let cellOptions = product.cells.map(cell => {return cell.cell})

	product.cells.map(cellItem => {
		let cell = new ProductCells(cellOptions, cellItem.cell, false, cellItem.item_quantity)
		cells.push(cell)
	})

	return cells
}

Consolidation.prototype.addCellsWithProductInWarehouse  = function (currentProduct, existCell, existingCells) {
	/**
	 * Добавляем ячейки в которых еще есть этот продукт на складе
	 * Map product -> items_in_storage
	 * if exist cell in storage and not selected =>
	 * add this cell to productsForCellArray
	 */
	let cells = []
	let cellOptions = currentProduct?.items_in_storage.map(cell => {return cell.cell})

	currentProduct?.items_in_storage.map(productInWarehouse => {
		if(productInWarehouse.damage) return

		// /**
		//  * if this cell will be selected and saved
		//  * go to next productInWarehouse
		//  */
		let findExistCell = _.find(existingCells, function (findItem) {
			return findItem.data.cellSelected?.id === productInWarehouse.cell_id
		})
		/**
		 * set storageCount from items_in_storage[index]
		 */
		if(findExistCell) {
			findExistCell.data.storageCount = productInWarehouse.quantity
			return
		}
		// if(existCell){
		// }

		let cell = new ProductCells(cellOptions, productInWarehouse.cell, productInWarehouse.quantity, false)

		cells.push(cell)
	})

	return cells
}

Consolidation.prototype.addOtherUserCells = function (item, existCell, existingCells) {
	/**
	 * Добавляем остальные ячейки юзера
	 */
	let cells = []
	let userCells = item?.user_cells

	userCells = _.sortBy(userCells, item => {return item.mixed})

	userCells.map(userCell => {

		let selectOptions = _.clone(userCells)
		let findExistCell = null

		existingCells.map(existCell => {
			// find existingCell and change flag
			if(existCell.data.cellSelected?.id === userCell.id) {
				findExistCell = true
			}

			// remove in selectOptions existingCells
			let foundCell = _.findIndex(selectOptions, {id: existCell.data.cellSelected?.id})
			if(foundCell > -1) {
				selectOptions.splice(foundCell, 1)
			}
		})

		// If exist -> next step
		if(findExistCell) {
			return
		}

		let cell = new ProductCells(selectOptions, userCell, false, false)

		cells.push(cell)
	})

	return cells
}

Consolidation.prototype.addOtherFreeCells = function (item) {
	/**
	 * Добавляем пустые ячейки
	 */
	let cells = []

	item?.free_cells_without_mixed.map(freeCell => {
		let cell = new ProductCells(item.free_cells_without_mixed, freeCell, false, false)

		cell.data.cellType = 'free'

		cells.push(cell)
	})

	return cells
}

Consolidation.prototype.addMixedFreeCells = function (item) {
	/**
	 * Добавляем пустые миксовые ячейки
	 */
	let cells = []

	item?.mixed_cells_without_this_user.map(freeCell => {
		let cell = new ProductCells(item.mixed_cells_without_this_user, freeCell, false, false)

		cells.push(cell)
	})

	return cells
}

Consolidation.prototype.addProductCountWithoutCell = function (product, productForCell) {
	product.product.items_in_storage.map(productInWarehouse => {
		if(!productInWarehouse.cell && productInWarehouse.cell_id) return
		if(productInWarehouse.damage) return

		if(!productInWarehouse.cell) {
			productForCell.data.withoutCellDeclaredCounts = parseInt(productForCell.data.withoutCellDeclaredCounts) + parseInt(productInWarehouse.quantity)
		}
	})
}

Consolidation.prototype.addProductExistCountWithoutCell = function (product, productForCell) {
	product.cells.map(cell => {
		if(!cell.cell && cell.cell_id) return
		if(cell.damage) return

		if(!cell.cell) {
			productForCell.data.withoutCellEndCounts = parseInt(productForCell.data.withoutCellEndCounts) + parseInt(cell.item_quantity)
		}
	})
}

Consolidation.prototype.resetProductsForCellArray = function () {
	this.data.productsForCellArray = []
}

Consolidation.prototype.resetProformProductsForTable = function () {
	this.data.proformProductsForTable = []
}

Consolidation.prototype.setProductsForCellArray = function (productEntities, item, that) {
	productEntities.map((productEntity) => {

		let {
			newProduct,
			cells,
			// existCell
		} = this.createProductModelAndCellsArray(productEntity, item, that)

		/**
		 * set product to ProductForCell model
		 * and add to productsForCellArray array
		 */
		let newIndex = this.addProformProductForCells(newProduct, cells, productEntity.count_damaged, productEntity?.id, productEntity.count_scanned_kyiv)

		/**
		 * Add Product Count Without Cell
		 */
		this.addProductCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

		/**
		 * Add Product Exist Count Without Cell
		 */
		this.addProductExistCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])
		// /**
		//  * set proformProduct to ProductForCell model and initialize proform
		//  */
		// that.initializeProductProformArray(this.data.productsForCellArray, newIndex)
		//
		// console.log(123456, productEntity?.proform[Object.keys(productEntity?.proform).find(Boolean)]);
		// let productData = {
		// 	productInstance: productEntity?.product,
		// 	proformData: productEntity?.proform[Object.keys(productEntity?.proform).find(Boolean)],
		// }
		//
		// this.data.productsForCellArray[newIndex].data.ProformProduct.setProductItem(productData)

		/**
		 * Sort ProductsForCellArray by storage quantity
		 */
		// if(!existCell){
			let productsForCellArrayItem = this.data.productsForCellArray[newIndex]

			let userArray = productsForCellArrayItem.data.UserProductCellArray
			let userMixedProductCellArray = productsForCellArrayItem.data.UserMixedProductCellArray

			productsForCellArrayItem.data.UserProductCellArray = this.sortCellArrayByStorageCount(userArray)
			productsForCellArrayItem.data.UserMixedProductCellArray = this.sortCellArrayByStorageCount(userMixedProductCellArray)
		// }

	})

	if(!that.isAdmin) {
		this.data.productsForCellArray.map((itemProductsForCell) => {
			let proforma = itemProductsForCell.data.ProformProduct
			that.initialProformSelectOptions({
				val: proforma.product.proformData.proformCategory,
				item: proforma
			})
		})
	}
}

Consolidation.prototype.addProduct = function (array) {
	array.push(new Product)
	return array.length - 1
}

Consolidation.prototype.addProformByProduct = function (proforms, byOrder = false, that = false) {
	// Add count proform products with response data

	console.log(12321321321321,proforms);
	if(!byOrder && Object.keys(proforms).length > 1){
		Object.keys(proforms).map(() => {
			// if only one product
			if(Object.keys(proforms).length === this.data.proformOrders.length) return
			this.addProformProduct(this.data.proformOrders)
		})
	}

	// this.data.proformProductsForTable = []

	let indexNewProform = null
	Object.keys(proforms).map((item, index) => {

		if(byOrder && _.first(this.data.proformOrders).product.proformEntityId !== ''){
			indexNewProform = this.addProformProduct(this.data.proformOrders)
		}
		if (byOrder && _.first(this.data.proformOrders).product.proformEntityId === '') {
			indexNewProform = 0
		}

		// console.log(111111222222, this.data.proformOrders, indexNewProform);
		that.initializeProductProformArray(this.data.proformOrders, indexNewProform)
		this.data.proformOrders[indexNewProform ? indexNewProform : index].setOrderProformItem(proforms[item], item, true)

		// console.log(5555555, this.data.proformOrders);
		//add order proform product to aggregate array
		this.addProformProductForTable(this.data.proformOrders[indexNewProform ? indexNewProform : index])

	})


	// set proform product data & selected value
	// if (!byOrder) {
	// 	Object.keys(proforms).map((item, index) => {
	// 		this.data.proformOrders[indexNewProform ? indexNewProform : index].setOrderProformItem(proforms[item], item)
	// 	})
	// }

}

Consolidation.prototype.addProformProduct = function (arrayProform) {
	arrayProform.push(new ProformProduct)
	return arrayProform.length - 1
}

/**
 * Functions
 */

Consolidation.prototype.addPromoCode = function () {
	this.data.promoCodes.push(new PromoCode())
	return this.data.promoCodes.length - 1
}

Consolidation.prototype.removePromoCode = function (index) {
	this.data.promoCodes.splice(index, 1)
	return this.data.promoCodes.length - 1
}

Consolidation.prototype.calcVolumeWeight = function() {
	if(
		this.data.Dimensions.data.height.length > 0 &&
		this.data.Dimensions.data.width.length > 0 &&
		this.data.Dimensions.data.dimensionLength.length > 0
	){
		let calculate = parseFloat((
			this.data.Dimensions.data.height.replaceAll(',', '.') *
			this.data.Dimensions.data.width.replaceAll(',', '.') *
			this.data.Dimensions.data.dimensionLength.replaceAll(',', '.')
		) / 5000)

		if(calculate < 0.01)
			this.data.volumeWeight = calculate.toFixed(4)
		else
			this.data.volumeWeight = calculate.toFixed(2)

	} else {
		this.data.volumeWeight = ''
	}
}

Consolidation.prototype.calcShippingCost = function(store, that) {
	if (
		this.data.User.getUserId() &&
		this.getConsolidationDestination()?.delivery_service_id &&
		this.data.Dimensions.data.weight.length > 0 &&
		this.data.Dimensions.data.dimensionLength.length > 0 &&
		this.data.Dimensions.data.width.length > 0 &&
		this.data.Dimensions.data.height.length > 0
	) {
		let data = {
			'user_id': this.data.User.getUserId(),
			'delivery_service_id': this.getConsolidationDestination()?.delivery_service_id,
			'dimensions': {
				'weight': this.data.Dimensions.getDimensionsWeight(),
				'length': this.data.Dimensions.getDimensionsLength(),
				'width': this.data.Dimensions.getDimensionsWidth(),
				'height': this.data.Dimensions.getDimensionsHeight(),
			}
		}
		if(that && that.isAdmin) {
			data['consolidation_id'] = this.data.id
		}

		if (this.getConsolidationDestination().delivery_service_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_EU_PRODUCT.delivery_service_id) {
			data['storage_id'] = this.getOrderWarehouse()?.id
		}

		store.dispatch('getConsolidationShippingCost', data).then((response) => {
			if (response.status === store.getters.GET_ERRORS.SUCCESS_CODE) {
				this.data.shippingCost = response?.data?.data?.packaging_cost

				if(that.getRespData(response)?.consolidation_price_amount_alert) {
					let text = {
						title: '',
						txt: '',
						customTxt: that.getRespData(response)?.consolidation_price_amount_alert,
						yes: '',
						no: 'common_cancel'
					}

					let confirm = () => {

						that.$store.commit('setConfirmDeletePopup', false)
						return true
					}

					that.deleteFunc(text, confirm)
				}
			}
			else {
				this.data.shippingCost = ''
			}
		})
	}
	else {
		this.data.shippingCost = ''
	}
}

Consolidation.prototype.addProductForCells = function (product = false, cells = false) {
	let productForCell = new ProductForCell(product)
	if(cells.length > 0){
		productForCell.setCellsByType(cells)
	}
	this.data.productsForCellArray.push(productForCell)
	return this.data.productsForCellArray.length - 1
}

Consolidation.prototype.addProformProductForCells = function (
	product = false,
	cells = false,
	countDamaged = false,
	proformDataId = false,
	scannedInKievCount = false,
) {
	let productForCell = new ProductForCell(false, product, countDamaged, scannedInKievCount)
	if(cells.userProductCells.length || cells.userCells.length || cells.freeCells.length || cells.mixedFreeCells.length){
		productForCell.setCellsByTypeInbound(cells)
	}

	if (proformDataId !== false) {
		productForCell.setId(proformDataId)
	}

	this.data.productsForCellArray.push(productForCell)
	return this.data.productsForCellArray.length - 1
}

Consolidation.prototype.sortCellArrayByStorageCount = function (userCellArray) {
	return _.sortBy(userCellArray, function (item) {
		return 0 + item.data.storageCount
	})
}

Consolidation.prototype.removeProductForCells = function (array, index) {
	this.data.proformProductsDeletedIds.push(array[index].data.ProformProduct.product.proformEntityId)
	array.splice(index, 1)
}

Consolidation.prototype.productsDataGeneration = function (array, withCells = false) {
	let data = []
	array.map(item => {
		let product = item.data.ProformProduct.product.productObject
		let productId = product?.id

		if(!productId) return

		let proformProduct = item.data.ProformProduct.prepareSaveProform()

		let itemData = {
			"product_id": productId,
			"item_quantity": item.data.ProformProduct.product.itemsQuantity,
			"item_price": item.data.ProformProduct.product.price,
			"count_damaged": item.data.damagedCount,
			"count_scanned_kyiv": item.data.scannedInKievCount,
			"proform_data": proformProduct,
		}

		if (item.data.id) {
			itemData['id'] = item.data.id
		}

		if(withCells){
			let cells = [],
				userProductCellArray = item.data.UserProductCellArray,
				userMixedProductCellArray = item.data.UserMixedProductCellArray,
				userCellArray = item.data.UserCellArray,
				mixedCellArray = item.data.MixedCellArray,
				freeCellArray = item.data.FreeCellArray

			cells = [...cells, ...this.getCellsByArray(userProductCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(userMixedProductCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(userCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(mixedCellArray, productId)]
			cells = [...cells, ...this.getCellsByArray(freeCellArray, productId)]

			if(parseInt(item.data.withoutCellEndCounts) !== 0) {
				cells.push({
					"product_id": productId,
					"cell_id": null,
					"item_quantity": parseInt(item.data.withoutCellEndCounts),
				})
			}

			itemData["cells"] = cells
		}

		data.push(itemData)
	})
	return data
}

Consolidation.prototype.getCellsByArray = function (array, productId, forPdf = false) {
	let cells = []
	array.map(itemCell => {
		if(itemCell.data.cellCount > 0 && !forPdf){
			cells.push({
				"product_id": productId,
				"cell_id": itemCell.data.cellSelected.id,
				"item_quantity": parseInt(itemCell.data.cellCount),
			})
		}
		else if (itemCell.data.cellCount > 0 && forPdf) {
			cells.push({
				'cell': {
					"combo_name": itemCell.data.cellSelected?.combo_name,
				}
			})
		}
	})
	return cells
}

/**
 * Prepare Data
 */

Consolidation.prototype.checkPromoCodePrepareData = function () {
	return {
		'dimensions': {
			'weight': this.data.Dimensions.getDimensionsWeight(),
			'length': this.data.Dimensions.getDimensionsLength(),
			'width': this.data.Dimensions.getDimensionsWidth(),
			'height': this.data.Dimensions.getDimensionsHeight(),
		},
		'user_id': this.data.User.getUserId(),
		'promocodes': this.data.promoCodes.map(code => {return {code: code.getCode()}})
	}
}


Consolidation.prototype.prepareSave = function (draft = false, isAdmin = false, edit = false) {
	//prepare dimensions object for api call
	let formattedDimensions = {
		'package_count': this.getNumberOfBoxes(),
		'weight': this.data.Dimensions.getDimensionsWeight(),
		'length': this.data.Dimensions.getDimensionsLength(),
		'width': this.data.Dimensions.getDimensionsWidth(),
		'height': this.data.Dimensions.getDimensionsHeight(),
	}

	//get related order ids as an array of ids
	let formattedOrders = [
		...this.getRelatedOrders().map(item => {
			return item.id
		})
	]

	let formattedProformData = this.data.proformProductsForTable.map(item => {
		return item.prepareSaveProform()
	})

	/// REWORK LATER !!! use different model
	let formattedProductData = []

	formattedProductData = this.productsDataGeneration(this.data.productsForCellArray, isAdmin)



	let preparedData = {
		'user_id': this.data.User.getUserId(),
		'consolidation_destination_id': this.getConsolidationDestination()?.id,
		'delivery_service_id': this.getDeliveryService()?.id,
		'shipped_at': this.getShippedAt(),
		'received_at': this.getReceivedAt(),
		'user_tracking_number': this.getTrackingNumber(),
		'comment': this.getComment(),
		'dimensions': formattedDimensions,
		'orders': formattedOrders,
		'proform_data': formattedProformData,
		'product_data': formattedProductData,
		'available_courier_time': null,
		'not_available_courier_time': null,
		// 'status': CONSOLIDATION_STATUSES.CONSOLIDATION_STATUS_IN_PROGRESS.value,
		'in_draft': draft,
		'storage_id': this.getOrderWarehouse()?.id,

	}

	let promocodes = []
	this.data.promoCodes.map(code => {
		if(code.getCode().length > 0) {
			promocodes.push({code: code.getCode()})
		}
	})

	if(promocodes.length > 0) {
		preparedData['promocodes'] = promocodes
	}

	if (isAdmin) {
		preparedData['order_process_fee'] = this.getOrderProcessFee().cost ? this.getOrderProcessFee().cost : null
		preparedData['admin_order_amount'] = this.getAdminOrderAmount()
		preparedData['packaging_amount'] = this.getPackagingAmount()
		preparedData['consolidation_union_id'] = this.getConsolidationUnion() ? this.getConsolidationUnion().id : null

		if(edit) {
			preparedData['vat_amount'] = this.getVatAmount()
		}
	}


	if (this.getConsolidationDestination().id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX_FROM_YOUR_CITY.id &&
	this.getConsolidationDestination().id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id &&
	this.getConsolidationDestination().id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id) {
		//check on prepare to prevent sending warehouse data when destination doesn't have one
		preparedData['warehouse_id'] = this.getConsolidationDestination().warehouses ? this.getWarehouse()?.id : null
	}
	else {
		preparedData['warehouse_id'] = this.data.sendType === this.data.sendTypes.warehouse ? this.getWarehouse()?.id || null : null
	}


	//fedex specific data
	if (this.getConsolidationDestination().delivery_service_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_FEDEX.delivery_service_id) {
		preparedData['drop_certification'] = this.getDropCertification()
		preparedData['toxic_substance'] = this.getToxicSubstance()

	}
	// //dhl specific data
	// if (this.getConsolidationDestination().delivery_service_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_DHL.delivery_service_id) {
	// 	preparedData['sender_contact_name'] = this.getSenderContactName()
	// }
	//tnt specific data
	if (this.getConsolidationDestination().delivery_service_id === CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.delivery_service_id) {
		preparedData['delivery_date'] = this.getDeliveryDate()
		preparedData['available_courier_time'] = this.getTimeCourierFrom() + "|" + this.getTimeCourierTo()
		preparedData['not_available_courier_time'] = this.data.lunchBreak ?
			this.getTimeNotAvailableFrom() + "|" + this.getTimeNotAvailableTo() : null
	}
	//specific data for api (but not tnt or own carrier)
	if (this.getConsolidationDestination()?.api === 1 &&
		this.getConsolidationDestination().delivery_service_id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_TNT.delivery_service_id &&
		this.getConsolidationDestination().id !== CONSOLIDATION_TYPES.CONSOLIDATION_TYPE_OWN_CARRIER.id &&
		this.data.sendType === this.data.sendTypes.sender) {
		preparedData['sender_contact_name'] = this.getSenderContactName()
	}

	// preparedData['status'] = null
	preparedData['mark'] = null
	if (this.getStatus() !== null) {
		preparedData['mark'] = this.getStatus()?.value
	}

	if(edit) {
		// console.log(this.data.proformOrdersDeletedIds);
		// console.log(this.data.proformProductsDeletedIds);
		preparedData['deleted_proform_ids'] = {
			proform_data: this.data.proformOrdersDeletedIds,
			product_data: this.data.proformProductsDeletedIds
		}
		this.data.proformOrdersDeletedIds = []
		this.data.proformProductsDeletedIds = []
	}

	if(this.data.sendFrom) {
		preparedData['send_from'] = {
			"first_name": this.data.sendFrom?.first_name,
			"last_name": this.data.sendFrom?.last_name,
			"company_name": this.data.sendFrom?.company_name
		}
	} else {
		preparedData['send_from'] = null
	}

	console.log(preparedData);

	return preparedData
}

Consolidation.prototype.prepareDataForPdf = function() {
	let formattedProductData = this.data.productsForCellArray.map(item => {
		let product = item.data?.ProformProduct?.product
		let productId = product?.id

		let cells = [],
			userProductCellArray = item.data.UserProductCellArray,
			userMixedProductCellArray = item.data.UserMixedProductCellArray,
			userCellArray = item.data.UserCellArray,
			mixedCellArray = item.data.MixedCellArray,
			freeCellArray = item.data.FreeCellArray

		cells = [...cells, ...this.getCellsByArray(userProductCellArray, productId, true)]
		cells = [...cells, ...this.getCellsByArray(userMixedProductCellArray, productId, true)]
		cells = [...cells, ...this.getCellsByArray(userCellArray, productId, true)]
		cells = [...cells, ...this.getCellsByArray(mixedCellArray, productId, true)]
		cells = [...cells, ...this.getCellsByArray(freeCellArray, productId, true)]

		if(parseInt(item.data.withoutCellEndCounts) !== 0) {
			cells.push({
				"cell": {
					"combo_name": '',
				}
			})
		}

		if (cells.length < 1) {
			cells.push({
				"cell": {
					"combo_name": '',
				}
			})
		}

		return {
			'item_quantity': product?.itemsQuantity,
			'item_price': product?.price,
			'count_scanned': item?.data?.scannedCount,
			'product': {
				'name_for_fbm': product?.productObject?.name_for_fbm,
				'items_in_storage': cells
			}
		}
	})

	return {
		'consolidation_destination_id': this.getConsolidationDestination()?.id,
		'product_data': formattedProductData,
	}
}

/**
 * Validations
 */
Consolidation.prototype.stepAValidation = function ({
	userValidate,
	consolidationDestination,
	shippedAt,
	warehouse,
	deliveryService,
	deliveryDate,
	dimensions,
	relatedOrders,
	status,
	receivedAt
}) {

	let validationItems = {
		consolidationDestination: this.data.consolidationDestination,
		shippedAt: this.data.shippedAt,
		warehouse: this.data.warehouse,
		deliveryService: this.data.deliveryService,
		deliveryDate: this.data.deliveryDate,
		relatedOrders: this.data.relatedOrders,
		status: this.data.status,
		receivedAt: this.data.receivedAt
	}


	let validationOptions = {
		consolidationDestination: {type: consolidationDestination ? ['empty'] : []},
		shippedAt: {type: shippedAt ? ['empty'] : []},
		warehouse: {type: warehouse ? ['empty'] : []},
		deliveryService: {type: deliveryService ? ['empty'] : []},
		deliveryDate: {type: deliveryDate ? ['empty'] : []},
		relatedOrders: {type: relatedOrders ? ['empty-array'] : []},
		status: {type: status ? ['empty'] : []},
		receivedAt: {type: receivedAt ? ['empty'] : []},
	}

	let userCheck = true
	if (userValidate) {
		userCheck = this.data.User.userValidation({userId: true})
	}

	if (dimensions && this.getNumberOfBoxes()) {
		validationItems['numberOfBoxes'] = this.data.numberOfBoxes
		validationOptions['numberOfBoxes'] = {type: ['is-integer']}
	}

	if(dimensions) {
		let dimensionsValidation = this.data.Dimensions.dimensionsValidate()
		return (this.checkValid(validationItems, validationOptions) && dimensionsValidation && userCheck)
	}

	return (this.checkValid(validationItems, validationOptions) && userCheck)
}


Consolidation.prototype.stepBValidation = function ({
	dimensions,
	relatedOrders
}) {

	let validationItems = {
		relatedOrders: this.data.relatedOrders,
	}

	let validationOptions = {
		relatedOrders: {type: relatedOrders ? ['empty-array'] : []},
	}

	if (dimensions) {
		let dimensionsValidation = this.data.Dimensions.dimensionsValidate()
		return (this.checkValid(validationItems, validationOptions) && dimensionsValidation)
	}

	return (this.checkValid(validationItems, validationOptions))
}

Consolidation.prototype.stepCValidation = function ({senderContactName}, that) {

	let validationItems = {}
	let validationOptions = {}

	if (senderContactName && this.getSenderContactName()) {
		validationItems['senderContactName'] = this.data.senderContactName
		validationOptions['senderContactName'] = {type: ['latin']}
	}

	return (this.checkValid(validationItems, validationOptions) && this.productsDefaultValidation(that))
}

Consolidation.prototype.stepDValidation = function ({
	senderContactName
}) {

	let validationItems = {
		senderContactName: this.data.senderContactName
	}

	let validationOptions = {
		senderContactName: {type: senderContactName ? ['latin', 'empty'] : []},
	}

	return (this.checkValid(validationItems, validationOptions))
}

Consolidation.prototype.ConsolidationAdminValidation = function (
	{
		userValidate,
		consolidationDestination,
		shippedAt,
		warehouse,
		deliveryService,
		deliveryDate,
		dimensions,
		relatedOrders,
		senderContactName,
		status,
		receivedAt,
		consolidationUnion,
	}
) {

	let validationItems = {
		consolidationUnion: this.getConsolidationUnion()?.id ? this.getConsolidationUnion().id : ''
	}

	let validationOptions = {
		consolidationUnion: {type: consolidationUnion ? ['empty'] : []},
	}

	return this.stepAValidation({
		userValidate: userValidate,
		consolidationDestination: consolidationDestination,
		shippedAt: shippedAt,
		warehouse: warehouse,
		deliveryService: deliveryService,
		deliveryDate: deliveryDate,
		dimensions: dimensions,
		relatedOrders: relatedOrders,
		status: status,
		receivedAt: receivedAt,
	}) && this.stepDValidation({senderContactName: senderContactName}) && (this.checkValid(validationItems, validationOptions))
}

Consolidation.prototype.productsDefaultValidation = function (that) {
	let valid = true

	this.data.productsForCellArray.map(item => {
		if (((item.data.ProformProduct?.product?.productObject && this.data.productsForCellArray.length < 2) || this.data.productsForCellArray.length > 1) &&
			!item.data.ProformProduct.productProformValidation({
			productObject: this.data.productsForCellArray.length > 1,
			proformCategory: true,
			itemsQuantity: true,
			price: true,
			proformMaterial: true,
		}))
			valid = false
	})

	if (!valid) {
		//заменяю массив на полную копию, чтобы Vue понимала что его нужно обновить во всех местах где он используется (была проблема что не обновлялась валидация)
		this.data.productsForCellArray = [...this.data.productsForCellArray]
	}

	if(this.checkDuplicateProduct()) {
		that.openNotify('error', 'common_notificationHasDuplicateProduct')
		valid = false
	}

	return valid
}

Consolidation.prototype.checkDuplicateProduct = function () {
	return this.data.productsForCellArray.some((productForCell, index) => {
		return this.data.productsForCellArray.some((productForCellFind, indexFind) => {
			if(index !== indexFind) {
				return productForCellFind.data.ProformProduct.product.productObject.id === productForCell.data.ProformProduct.product.productObject.id
			} else {
				return false
			}
		})
	})
}

Consolidation.prototype.ConsolidationCellCountsValidate = function (isAdminEdit) {
	if(!isAdminEdit) return true

	let valid = true
	this.data.productsForCellArray.map(product => {
		if(product.data.productCount !== product.data.scannedCount) {
			valid = false
		}
	})

	return valid
}

Consolidation.prototype.ConsolidationScannedInKievValidate = function () {

	let valid = true
	this.data.productsForCellArray.map(product => {
		if(product.data.scannedInKievCount === 0) {
			valid = false
		}
	})

	return valid
}



