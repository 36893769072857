import {checkValid} from "./functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";

export function PromoCode() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    code: '',
    File: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),
  }


  this.validation = {
    id: false,
    code: false,
  }

  this.validationTranslate = {
    id: '',
    code: '',
  }

  this.validationTxt = {
    id: false,
    code: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getCode = () => {
    return this.data.code
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setCode = (val) => {
    this.data.code = val
  }

}

/**
 * Global Setters
 */

PromoCode.prototype.setItem = function(val) {
  this.setId(val?.id ? val.id : '')
  this.setCode(val?.code ? val.code : '')
}


/**
 * Prepare Data
 */

PromoCode.prototype.prepareSave = function(useCode = false) {

  if(useCode) {
    return {
      code: this.getCode(),
    }
  }

  return {
    code: this.getCode(),
  }
}


/**
 * Validations
 */

PromoCode.prototype.firstValidate = function (
  {
    id,
    code,
  }) {

  let validationItems = {
    id: this.getId(),
    code: this.getCode(),
  }

  let validationOptions = {
    id: {type: id ? ['empty'] : []},
    code: {type: code ? ['empty'] : []},
  }


  return this.checkValid(validationItems, validationOptions)
}
