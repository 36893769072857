<template>
  <div class="title-horizontal">
    <div class="title-horizontal__inner">
      <div class="title-horizontal__text">
        {{value}}
      </div>
      <div class="title-horizontal__btn"
        v-if="typeBtn == 'edit'"
        @click="clickEdit"
      >
        {{ typeBtnText }}
        <div class="title-horizontal__btn-ico">
          <EditBlack/>
        </div>
      </div>
      <div class="title-horizontal__btn"
        v-if="typeBtn == 'custom'"
        @click="clickEdit"
      >
        {{ customName }}
        <div class="title-horizontal__btn-ico">
          <EditBlack/>
        </div>
      </div>

      <div class="title-horizontal--last" v-if="rightBtn"
          @click="rightBtnClick"
      >
        <IconLinkBtn :type="rightBtnType" :text="rightBtnText"
                      :class="{ 'style-reverse' : rightBtnType === 'edit'}"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import EditBlack from '../../../../public/img/common/edit-black.svg?inline'
  import IconLinkBtn from "../../UI/buttons/IconLinkBtn/IconLinkBtn.vue";

  export default {
    name: "TitleHorizontal",
    components: {
      EditBlack,
      IconLinkBtn,
    },

    props: [
      'value',
      'typeBtn',
      'typeBtnText',
      'customName',
      'rightBtn',
      'rightBtnType',
      'rightBtnText',
    ],

    methods: {
      clickEdit(){
        this.$emit('clickEdit');
      },

      rightBtnClick(){
        this.$emit('rightBtnClick');
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";
  @import "../../../scss/mixins/mixins";

  .title-horizontal{
    margin-bottom: 24px;
    overflow: hidden;

    @include for-680{
      margin-bottom: 15px;
    }

    &__inner{
      display: flex;
      align-items: center;
      position: relative;
      //width: fit-content;

      &:after{
        content: '';
        //left: calc(100% + 5px);
        //position: absolute;
        //top: 50%;
        //transform: translateY(-50%);
        //height: 1px;
        //background: $accent;
        //width: 100vw;
        height: 1px;
        background: $accent;
        width: 100%;
        margin-right: 0;
        margin-left: 7px;
      }
    }

    &__text{
      color: $accent;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      min-width: fit-content;
    }

    &__btn{
      display: flex;
      margin-left: 7px;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      color: $black;
      transition: .3s;
      min-width: fit-content;

      &:hover{
        color: $brown;

        .title-horizontal__btn-ico svg path{
          stroke: $brown;
        }
      }
    }

    &__btn-ico{
      margin-left: 5px;

      svg path{
        transition: .3s;
      }
    }

    &--last{
      order: 10;
      min-width: fit-content;
      margin-left: 8px;
    }


    &.black{

      .title-horizontal__text{
        color: $black;
      }

      .title-horizontal__inner:after{
        background: #F3EBDF;
      }


    }

  }

</style>
