<template>
  <span class="icon-link-btn"
        :class="{'black-btn' : type === 'delete' || type === 'edit'}"
  >
    <AddToProfile class="icon-link-btn__ico" v-if="type==='add-to-profile'" />
    <DeleteProduct class="icon-link-btn__ico" v-if="type==='delete'" />
    <EditBlack class="icon-link-btn__ico" style="transform: translateY(-2px);" v-if="type==='edit'" />
    <span class="icon-link-btn__txt">{{text}}</span>

  </span>
</template>

<script>
  import AddToProfile from '../../../../../public/img/UI-group/add-to-profile.svg?inline'
  import DeleteProduct from '../../../../../public/img/UI-group/delete-product-ico.svg?inline'
  import EditBlack from '../../../../../public/img/common/edit-black.svg?inline'

  export default {
    name: "IconLinkBtn",

    components: {
      AddToProfile,
      DeleteProduct,
      EditBlack,
    },

    props: ['type', 'text'],
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/colors";

  .icon-link-btn{
    display: flex;
    align-items: center;

    &__txt{
      margin-left: 5px;
      color: $accent;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &.black-btn{
      cursor: pointer;

      .icon-link-btn__txt{
        color: $black;
      }
    }

    &.style-reverse{
      flex-direction: row-reverse;
      .icon-link-btn__txt{
        margin-left: 0;
      }

      .icon-link-btn__ico{
        margin-left: 5px;
      }
    }
  }

</style>
