import {checkValid} from "./functions/ModelsValidate";

export function Order() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    orderOBJ: null,
    isDraft: false,
    createdAt: '',
    status: '',
    oldStatus: '',
    type: '',
    transaction: '',
    transactionType: '',
    transactionId: '',
    issetTransaction: false,
    trackingNum: '',
    warehouse: null,
    issetPaymentTransaction: false,

    isWasBeenCreateLabel: false,

    historyOfChanged: false,

    fileLabel: null

    // User: new User(),
  }


  this.validation = {
    id: false,
    type: false,
    status: false,
    transaction: false,
    transactionType: false,
    transactionId: false,
    issetTransaction: false,
    trackingNum: false,
    warehouse: false,
  }

  this.validationTranslate = {
    id: '',
    type: '',
    status: '',
    transaction: '',
    transactionType: '',
    transactionId: '',
    issetTransaction: '',
    trackingNum: '',
    warehouse: '',
  }

  this.validationTxt = {
    id: false,
    type: false,
    status: false,
    transaction: false,
    transactionType: false,
    transactionId: false,
    issetTransaction: false,
    trackingNum: false,
    warehouse: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getOrderOBJ = () => {
    return this.data.orderOBJ
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getOldStatus = () => {
    return this.data.oldStatus
  }
  this.getIsDraft = () => {
    return this.data.isDraft
  }
  this.getType = () => {
    return this.data.type
  }
  this.getTransaction = () => {
    return this.data.transaction
  }
  this.getTransactionType = () => {
    return this.data.transactionType
  }
  this.getTransactionId = () => {
    return this.data.transactionId
  }
  this.getIssetTransaction = () => {
    return this.data.issetTransaction
  }
  this.getTrackingNum = () => {
    return this.data.trackingNum
  }
  this.getCreatedAt = () => {
    return this.data.createdAt
  }
  this.getIsWasBeenCreateLabel = () => {
    return this.data.isWasBeenCreateLabel
  }
  this.getHistoryOfChanged = () => {
    return this.data.historyOfChanged
  }
  this.getWarehouse = () => {
    return this.data.warehouse
  }
  this.getWarehouseCountry = () => {
    return this.data.warehouse?.warehouse?.country
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setOrderOBJ = (val) => {
    this.data.orderOBJ = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setOldStatus = (val) => {
    this.data.oldStatus = val
  }
  this.setIsDraft = (val) => {
    this.data.isDraft = val
  }
  this.setCreatedAt = (val) => {
    this.data.createdAt = val
  }

  this.setType = (val) => {
    this.data.type = val
  }
  this.setTransaction = (val) => {
    this.data.transaction = val
  }
  this.setTransactionType = (val) => {
    this.data.transactionType = val
  }
  this.setTransactionId = (val) => {
    this.data.transactionId = val
  }
  this.setIssetTransaction = (val) => {
    this.data.issetTransaction = val
  }
  this.setTrackingNum = (val) => {
    this.data.trackingNum = val
  }
  this.setIsWasBeenCreateLabel = (val) => {
    this.data.isWasBeenCreateLabel = val
  }
  this.setFileLabel = (val) => {
    this.data.fileLabel = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }
  this.setWarehouse = (val) => {
    this.data.warehouse = val
  }

}

/**
 * Global Setters
 */

Order.prototype.setItem = function(val) {
  this.setId(val?.id ? val.id : '')
  this.setStatus(val?.status ? val.status : '')
  this.setOldStatus(val?.oldStatus ? val.oldStatus : '')
  this.setCreatedAt(val?.createdAt ? val.createdAt : '')
  this.setType(val?.type ? val.type : '')
  this.setTrackingNum(val?.trackingNum ? val.trackingNum : '')
  this.setWarehouse(val?.warehouse ? val.warehouse : '')
  this.setIsWasBeenCreateLabel(Object.hasOwnProperty.call(val, 'isWasBeenCreateLabel') ? val.isWasBeenCreateLabel : false)
  this.setFileLabel(val?.fileLabel ? val.fileLabel : null)
  this.setIsDraft(Object.hasOwnProperty.call(val, 'isDraft') ? val.isDraft : false)
  this.setTransactionType(val.transactionType ? val.transactionType : '')
  this.setTransactionId(val.transactionId ? val.transactionId : '')
  this.setTransaction(val ? val : '')
}


/**
 * Prepare Data
 */

Order.prototype.prepareSave = function() {

}


/**
 * Validations
 */

Order.prototype.orderValidate = function (
  {
    id,
    type,
    status,
    transaction,
    transactionType,
    transactionId,
    issetTransaction,
    trackingNum,
    warehouse,
  }) {

  let validationItems = {
    id: this.data.id,
    type: this.data.type,
    status: this.data.status,
    transaction: this.data.transaction,
    transactionType: this.data.transactionType,
    transactionId: this.data.transactionId,
    issetTransaction: this.data.issetTransaction,
    trackingNum: this.data.trackingNum,
    warehouse: this.data.getWarehouse()?.id,
  }

  let validationOptions = {
    id: {type: id ? ['empty'] : []},
    type: {type: type ? ['empty'] : []},
    status: {type: status ? ['empty'] : []},
    transaction: {type: transaction ? ['empty'] : []},
    transactionType: {type: transactionType ? ['empty'] : []},
    transactionId: {type: transactionId ? ['empty'] : []},
    issetTransaction: {type: issetTransaction ? ['empty'] : []},
    trackingNum: {type: trackingNum ? ['empty'] : []},
    warehouse: {type: warehouse ? ['empty'] : []},
  }


  return this.checkValid(validationItems, validationOptions)
}
