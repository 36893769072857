import {checkValid} from "./functions/ModelsValidate";
import _ from "lodash";
import {RUSSIA_COUNTRY_ID} from "../../staticData/staticVariables";

export function User() {

  this.checkValid = checkValid

  this.userId = ''

  this.userName = ''
  this.userFirstName = ''
  this.userLastName = ''
  this.userCyrillicName = ''
  this.userPassword = ''
  this.userRepeatPassword = ''

  this.userAvatar = {
    id: null,
    base64: null,
    mimeType: null,
    uuid: null
  }
  this.userBigAvatar = {
    id: null,
    base64: null,
    mimeType: null,
    uuid: null
  }

  this.userEmail = ''
  this.userAdditionalEmail = ''
  this.userPhone = ''
  this.userAddress = ''
  this.userAddressCyrilic = ''
  this.userCompany = ''
  this.userCity = ''
  this.userCountry = ''
  this.userRegion = ''
  this.userZip = ''
  this.userAlternativeAddress = ''
  this.userAlternativeCity = ''
  this.userAlternativeCountry = ''
  this.userAlternativeRegion = ''
  this.userAlternativeZip = ''
  this.userITN = ''
  this.userVAT = ''
  this.birthday = ''

  this.user = {}


  this.validation = {
    userId: false,
    userName: false,
    userFirstName: false,
    userLastName: false,
    userCyrillicName: false,
    userPassword: false,
    userRepeatPassword: false,
    user: false,

    userEmail: false,
    userAdditionalEmail: false,
    userPhone: false,
    userAddress: false,
    userAddressCyrilic: false,
    userCity: false,
    userCountry: false,
    userRegion: false,
    userZip: false,
    userAlternativeAddress: false,
    userAlternativeCity: false,
    userAlternativeCountry: false,
    userAlternativeRegion: false,
    userAlternativeZip: false,
    userITN: false,
    userVAT: false,
  }

  this.validationTranslate = {
    userId: '',
    userName: '',
    userFirstName: '',
    userLastName: '',
    userCyrillicName: '',
    userPassword: '',
    userRepeatPassword: '',
    user: '',

    userEmail: '',
    userAdditionalEmail: '',
    userPhone: '',
    userAddress: '',
    userAddressCyrilic: '',
    userCity: '',
    userCountry: '',
    userRegion: '',
    userZip: '',
    userAlternativeAddress: '',
    userAlternativeCity: '',
    userAlternativeCountry: '',
    userAlternativeRegion: '',
    userAlternativeZip: '',
    userITN: '',
    userVAT: '',
  }

  this.validationTxt = {
    userId: false,
    userName: false,
    userFirstName: false,
    userLastName: false,
    userCyrillicName: false,
    userPassword: false,
    userRepeatPassword: false,
    user: false,

    userEmail: false,
    userAdditionalEmail: false,
    userPhone: false,
    userAddress: false,
    userAddressCyrilic: false,
    userCity: false,
    userCountry: false,
    userRegion: false,
    userZip: false,
    userAlternativeAddress: false,
    userAlternativeCity: false,
    userAlternativeCountry: false,
    userAlternativeRegion: false,
    userAlternativeZip: false,
    userITN: false,
    userVAT: false,
  }


  /**
   * Getters
   */
  this.getUser = () => {
    return this.user
  }
  this.getUserId = () => {
    return this.userId
  }
  this.getUserName = () => {
    return this.userName
  }
  this.getUserFirstName = () => {
    return this.userFirstName
  }
  this.getUserLastName = () => {
    return this.userLastName
  }
  this.getUserCyrillicName = () => {
    return this.userCyrillicName
  }
  this.getUserPassword = () => {
    return this.userPassword
  }
  this.getUserEmail = () => {
    return this.userEmail
  }
  this.getUserAdditionalEmail = () => {
    return this.userAdditionalEmail
  }
  this.getUserPhone = () => {
    return this.userPhone
  }
  this.getUserAddress = () => {
    return this.userAddress
  }
  this.getUserAddressCyrilic = () => {
    return this.userAddressCyrilic
  }
  this.getUserCompany = () => {
    return this.userCompany
  }
  this.getUserCity = () => {
    return this.userCity
  }
  this.getUserCountry = () => {
    return this.userCountry
  }
  this.getUserRegion = () => {
    if(this.userRegion?.id){
      return this.userRegion.id
    }
    if(this.userRegion !== ''){
      return this.userRegion
    }

    return ''
  }
  this.getUserZip = () => {
    return this.userZip
  }
  this.getUserAlternativeAddress = () => {
    return this.userAlternativeAddress
  }
  this.getUserAlternativeCity = () => {
    return this.userAlternativeCity
  }
  this.getUserAlternativeCountry = () => {
    return this.userAlternativeCountry
  }
  this.getUserAlternativeRegion = () => {
    if(this.userAlternativeRegion?.id){
      return this.userAlternativeRegion.id
    }
    if(this.userAlternativeRegion !== ''){
      return this.userAlternativeRegion
    }

    return ''
  }
  this.getUserAlternativeZip = () => {
    return this.userAlternativeZip
  }
  this.getUserITN = () => {
    return this.userITN
  }
  this.getUserVAT = () => {
    return this.userVAT
  }
  this.getUserBirthday = () => {
    return this.birthday
  }

  /**
   * Setters
   */
  this.setUser = (val) => {
    this.user = val
    this.setUserId(val.id)

    let userInformation

    if (val?.contacts) {
      userInformation = _.first(val?.contacts)
    } else {
      userInformation = val?.user_personal_contact
    }

    const userAlternativeAddress = val?.user_alternative_contact

    this.setUserName(userInformation?.user_full_name)

    this.setUserEmail(userInformation?.email)
    this.setUserPhone(userInformation?.phone)
    this.setUserAddress(userInformation?.address)
    this.setUserAddressCyrilic(userInformation?.cyrillic_address)
    this.setUserCity(userInformation?.city)
    this.setUserCountry(userInformation?.country)
    this.setUserRegion(userInformation?.region)
    this.setUserZip(userInformation?.zip)
    this.setUserAlternativeAddress(userAlternativeAddress?.address)
    this.setUserAlternativeCity(userAlternativeAddress?.city)
    this.setUserAlternativeCountry(userAlternativeAddress?.country)
    this.setUserAlternativeRegion(userAlternativeAddress?.region)
    this.setUserAlternativeZip(userAlternativeAddress?.zip)
    this.setUserITN(val.itn_number)

    this.setUserVAT(val?.vat_number)
    this.setUserFirstName(userInformation?.first_name)
    this.setUserLastName(userInformation?.last_name)
    this.setUserCyrillicName(userInformation?.cyrillic_full_name)
    this.setUserAdditionalEmail(userInformation?.additional_email)
    this.setUserCompany(userInformation?.company_name)
    this.setUserBirthday(userInformation?.birthday)
  }

  this.setUserId = (val) => {
    this.userId = val
  }

  this.setUserAvatar = (val) => {
    this.userAvatar = {
      id: val.id ? val.id : null,
      base64: val.base64 ? val.base64 : null,
      mimeType: val.mimeType ? val.mimeType : null,
      uuid: val.uuid ? val.uuid : null
    }
  }
  this.setUserBigAvatar = (val) => {
    this.userBigAvatar = {
      id: val.id ? val.id : null,
      base64: val.base64 ? val.base64 : null,
      mimeType: val.mimeType ? val.mimeType : null,
      uuid: val.uuid ? val.uuid : null
    }
  }
  this.setUserName = (val) => {
    this.userName = val ? val : ''
  }
  this.setUserFirstName = (val) => {
    this.userFirstName = val ? val : ''
  }
  this.setUserLastName = (val) => {
    this.userLastName = val ? val : ''
  }
  this.setUserCyrillicName = (val) => {
    this.userCyrillicName = val ? val : ''
  }
  this.setUserPassword = (val) => {
    this.userPassword = val ? val : ''
  }
  this.setUserEmail = (val) => {
    this.userEmail = val ? val : ''
  }
  this.setUserAdditionalEmail = (val) => {
    this.userAdditionalEmail = val ? val : ''
  }
  this.setUserPhone = (val) => {
    this.userPhone = val ? val : ''
  }
  this.setUserAddress = (val) => {
    this.userAddress = val ? val : ''
  }
  this.setUserAddressCyrilic = (val) => {
    this.userAddressCyrilic = val ? val : ''
  }
  this.setUserCompany = (val) => {
    this.userCompany = val ? val : ''
  }
  this.setUserCity = (val) => {
    this.userCity = val ? val : ''
  }
  this.setUserCountry = (val) => {
    this.userCountry = val ? Object.assign({label: val.name}, val) : ''
  }
  this.setUserRegion = (val) => {
    this.userRegion = val ? Object.assign({label: val.name}, val) : ''
  }
  this.setUserZip = (val) => {
    this.userZip = val ? val : ''
  }
  this.setUserAlternativeAddress = (val) => {
    this.userAlternativeAddress = val ? val : ''
  }
  this.setUserAlternativeCity = (val) => {
    this.userAlternativeCity = val ? val : ''
  }
  this.setUserAlternativeCountry = (val) => {
    this.userAlternativeCountry = val ? Object.assign({label: val.name}, val) : ''
  }
  this.setUserAlternativeRegion = (val) => {
    this.userAlternativeRegion = val ? Object.assign({label: val.name}, val) : ''
  }
  this.setUserAlternativeZip = (val) => {
    this.userAlternativeZip = val ? val : ''
  }
  this.setUserITN = (val) => {
    this.userITN = val ? val : ''
  }
  this.setUserVAT = (val) => {
    this.userVAT = val ? val : ''
  }
  this.setUserBirthday = (val) => {
    this.birthday = val ? val : ''
  }

}


/**
 * Global Setters
 */

User.prototype.setItem = function () {

}


User.prototype.setUserData = function (user) {

  if (!user?.id) return

  let userInformation

  if (user?.contacts) {
    userInformation = _.first(user?.contacts)
  } else {
    userInformation = user?.user_personal_contact
  }

  this.setUser(user)

  return userInformation

}

/**
 * Validations
 */

User.prototype.userValidation = function (
  {
    userId,
    userEmail,
    userPhone,
    userAddress,
    userCity,
    userCountry,
    userRegion,
    userZip,
    userAlternativeAddress,
    userAlternativeCity,
    userAlternativeCountry,
    userAlternativeRegion,
    userAlternativeZip,
    userITN,

    userFirstName,
    userLastName,
    userCompany,
    userPassword,
  }) {
  let validationItems = {
    userId: this.userId,

    userEmail: this.getUserEmail(),
    userPhone: this.getUserPhone(),
    userAddress: this.userAddress,
    userAddressCyrilic: this.userAddressCyrilic,
    userCity: this.userCity,
    userCountry: this.userCountry.id ? this.userCountry.id : '',
    userRegion: this.getUserRegion(),
    userZip: this.userZip,
    userAlternativeAddress: this.userAlternativeAddress,
    userAlternativeCity: this.userAlternativeCity,
    userAlternativeCountry: this.userAlternativeCountry.id ? this.userAlternativeCountry.id : '',
    userAlternativeRegion: this.getUserAlternativeRegion(),
    userAlternativeZip: this.userAlternativeZip,
    userITN: this.userITN,

    userFirstName: this.getUserFirstName(),
    userLastName: this.getUserLastName(),
    userCompany: this.getUserCompany(),
    userCyrillicName: this.getUserCyrillicName(),
    userPassword: this.getUserPassword(),
  }

  let validationOptions = {
    userId: {type: userId ? ['empty'] : []},

    userEmail: {type: userEmail ? ['email', 'empty'] : []},
    userPhone: {type: userPhone ? ['phone', 'empty'] : []},
    userAddress: {type: userAddress ? ['latin', 'empty'] : []},
    userCity: {type: userCity ? ['latin', 'empty'] : []},
    userCountry: {type: userCountry ? ['empty'] : []},
    userRegion: {type: userRegion ? ['empty'] : []},
    userZip: {type: userZip ? ['empty'] : []},
    userAlternativeAddress: {type: userAlternativeAddress ? ['latin', 'empty'] : []},
    userAlternativeCity: {type: userAlternativeCity ? ['latin', 'empty'] : []},
    userAlternativeCountry: {type: userAlternativeCountry ? ['empty'] : []},
    userAlternativeRegion: {type: userAlternativeRegion ? ['empty'] : []},
    userAlternativeZip: {type: userAlternativeZip ? ['empty'] : []},
    userITN: {type: userITN ? ['empty'] : []},


    userFirstName: {type: userFirstName ? ['latin', 'empty'] : []},
    userLastName: {type: userLastName ? ['latin', 'empty'] : []},
    userCompany: {type: userCompany ? ['latin'] : []},
    userCyrillicName: {type: userCompany ? ['onlyCyrillic'] : []},
    userPassword: {type: userPassword ? ['password'] : []},

  }

  if(this.getUserCountry().id === RUSSIA_COUNTRY_ID) {
    validationOptions.userAddressCyrilic = {type: ['onlyCyrillic']}
  }


  return this.checkValid(validationItems, validationOptions)
}

/**
 * Prepare Data
 */

User.prototype.prepareSave = function () {
  let data = {
    'user_id': this.getUserId(),
    'address': this.getUserAddress(),
    'city': this.getUserCity(),
    'country_id': this.getUserCountry().id,
    'country_region_id': this.getUserRegion(),
    'zip': this.getUserZip(),
    'itn_number': this.getUserITN(),
  }

  if (this.getUserCountry()?.country_group?.fromSNGGroup) {
    data.userITN = this.getUserITN()
  }

  if(this.getUserCountry().id === RUSSIA_COUNTRY_ID){
    data.cyrillic_address = this.getUserAddressCyrilic()
  }

  return data
}

User.prototype.prepareAlternativeAddressSave = function () {
  let data = {
    'user_id': this.getUserId(),
    'address': this.getUserAlternativeAddress(),
    'city': this.getUserAlternativeAddress() ? this.getUserAlternativeCity() : null,
    'country_id': this.getUserAlternativeAddress() ? this.getUserAlternativeCountry().id : null,
    'country_region_id': this.getUserAlternativeAddress() ? this.getUserAlternativeRegion() : null,
    'zip': this.getUserAlternativeAddress() ? this.getUserAlternativeZip() : null,
  }
  return data
}

User.prototype.prepareSavePersonalInfo = function () {
  return {
    email: this.getUserEmail(),
    vat_number: this.getUserVAT(),
    contacts: {
      first_name: this.getUserFirstName(),
      last_name: this.getUserLastName(),
      company_name: this.getUserCompany(),
      additional_email: this.getUserAdditionalEmail(),
      cyrillic_full_name: this.getUserCyrillicName(),
      birthday: this.getUserBirthday(),
    }
  }
}


